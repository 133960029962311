.modal-header-container {
  display: flex;
  flex-direction: row;
  background: radial-gradient(
      50% 50% at 50% 50%,
      #363840 0%,
      rgba(57, 61, 78, 0) 100%
    ),
    #232631;
  border-radius: 0.5rem 0 0 0;

  .modal-close-icon {
    margin-top: -1.4rem;
    margin-right: -1.4rem;
  }

  .modal-header {
    padding: 0.625rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .mobile-back-button {
      display: none;
    }

    span {
      color: white;
      font-family: Poppins;
      font-style: normal;
      font-weight: 700;
      font-size: 1.25rem;
      text-shadow: 1px 1px 0px #494949;
      padding-left: 1.5rem;
    }
  }
}

@media (max-width: 768px) {
  .modal-header-container {
    margin-top: 0 !important;
  
    .modal-header {
      .mobile-back-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        left: 1rem;

        span {
          font-size: 1rem;
          padding-left: 0;
        }
      }
    }
  }

}