/* Temp loading block to hover the map during first render */
.disable-map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.7;
  z-index: 99999;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  
  p {
    margin: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    line-height: 24px;
    vertical-align: top;

    span {
      display: block;
    }
  }
}


#map {
  position: absolute;
  margin-top: 3.125rem;

  img {
    position: absolute;
  }

  .field {
    clip-path: polygon(50% 10%, 100% 46%, 100% 63%, 50% 100%, 0 63%, 0 48%);
  }

  .heptagon {
    clip-path: polygon(
      50% 0,
      81% 16%,
      100% 45%,
      100% 63%,
      50% 100%,
      0 63%,
      0 48%,
      16% 25%
    );
  }
}
