  .select-wallet-modal {
    width: 35%;
    margin: 0 auto;
    margin-top: 10%;
    
    .header {
      text-align: center;
      font-weight: bold;
      font-size: 1.3rem;
      color: #ccc;
    }

    @media (max-width: 768px) {
      margin-top: 0;
      width: 100%;
    }

    @media (max-width: 400px) {
      .modal-header > span {
        font-size: 0.9rem;
      }
    }
  }

  .modal-body-select-wallet {
    .wallet-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chain-name {
        color: #ccc;
        font-size: 0.9rem;
      }
    }
    .wallet-container > div {
      height: 3.5rem;
      display: flex;
      align-items: center;

      span {
        color: #fff;
        font-family: Poppins;
        font-size: 1.1rem;
        font-weight: bold;
      }

      img {
        width: 30px;
        height: 30px;
        margin-right: 1rem;
      }
    }
  }