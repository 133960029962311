.top-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.625rem;

  p {
    color: white;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
  }

  .nav-items {
    display: flex;
    flex-direction: row;
    
    .nav-item:not(:last-child):hover {
      background: #ffffff2b;
      border-radius: 0.5rem;
    }
    
    .nav-item.quests-button {
      &:hover {
        background: none;
      }
    }

    .nav-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 12px;

      p a {
        color: white;
        font-family: Poppins;
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        margin-left: 4px;
        text-decoration: none;
      }
      
    }
  }
}

.disconnect-chain-button {
  display: flex;
  align-items: center;
  &:hover {
    background-color: #1a1f2e;
  }
  span {
    font-family: 'DM Sans', 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  img {
    margin-right: 12px;
  }
}

.hamburger, .mobMenuItems {
  display: none;
}

.select-chain-button, .disconnect-chain-button {
  background-color: #512da8;
  height: 3rem;
  padding: 0 1.5rem;
  border-radius: 4px;
  border: none;
  span {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
  }
  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .top-navigation {
    width: 100%;
    height: 4.35rem;
    padding: 4%;
    background: rgba(35, 38, 49, 0.8);
    box-shadow: 0 1em 1.5em rgb(0 0 0 / 15%);
    backdrop-filter: blur(100px);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 0;
    
    .nav-items {
      display: none;
    }
  
    .hamburger {
      display: flex;
      width: 2.2rem;
      height: 2.2rem;
    }

    .hamburger:hover {
      cursor: pointer;
    }
  }

  .mobMenuItems {
    display: flex;
    flex-direction: column;
    background: rgba(35, 38, 49, 0.8);
    width: 100%;

    .questsMenuItem {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-left: 1rem;
      }
    }

    .menuText {
      color: #ffffff;
      font-family: Poppins;
      font-size: 1.1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 1rem;
    }
    .divider {
      height: 1px;
      margin: 0 1rem;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .connect-button {
      margin: 0 auto;
    }
  }
}


.MuiMenu-root {
  div {
    background-color: transparent;
  }
}

.MuiMenu-list {
  background-color: #232631;
  border-radius: 0.5rem;
  padding: 0 !important;
  margin: 0 !important;

  .dropdown-menu-item {
    min-width: 9rem;
    height: 3rem;
    color: white;
    font-size: 1rem;
    font-family: Poppins;

    &:hover {
      background-color: #727375;
    }
  }
}