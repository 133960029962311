.buildings-modal-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem;

  .building-button {
    margin-top: 0.8rem;
    height: 3.5rem;
    width: 100%;
  }

  .buy-button {
    cursor: not-allowed;
  }

  .building-info-container-wrapper {
    position: relative;
    width: 31.5%;

    .lock-icon-container {
      position: absolute;
      z-index: 2;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .building-details-container {
      margin-top: 3.125rem;
      .building-status-text {
        color: #b4b6c1;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: -3rem;
        margin-bottom: 0.8rem;
      }
    }

    p.building-level {
      text-transform: uppercase;
      color: #FFF;
      font-size: 1.125rem;
      font-weight: 700;
      z-index: 99;
      text-align: center;
      background: url('../../../public/images/levelWrapper.png');
      background-size: cover;
      padding: 0.625rem;
      min-height: 4.2rem;
    }
  }

  .building-info-container {
    display: flex;
    flex-direction: column;
    background: radial-gradient( 49.55% 33.08% at 50% 34.91%, #a36ef794 0%, #9a69f861 24.65%, rgba(26, 28, 33, 0.03) 88.19% ), #232631;
    width: 33%;
    padding: 1.2rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 1px solid #925fff;

    .building-details-container {
      margin-top: 3.125rem;

      .building-status-text {
        color: #b4b6c1;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: -3rem;
        margin-bottom: 0.8rem;
      }
    }

    p.building-level {
      text-transform: uppercase;
      color: #FFF;
      font-size: 1.125rem;
      font-weight: 700;
      z-index: 99;
      text-align: center;
      background: url('../../../public/images/levelWrapper.png');
      background-size: cover;
      padding: 0.625rem;
      min-height: 4.2rem;
    }
  }
  .building-info-container::before {
    content: "";
    position: absolute;
    border-radius: 8px;
    border: 1px solid transparent;
    background: radial-gradient(
          100% 100% at 49.78% 0%,
          #925fff 0%,
          #c580f3 39.75%,
          rgba(255, 255, 255, 0) 93.4%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      linear-gradient(180deg, #232631 0%, rgba(0, 0, 0, 0) 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}


img.building-image {
  max-width: 230px;
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
}


.building-info-container-locked {
  filter: grayscale(100%);
  display: flex;
  flex-direction: column;
  background: radial-gradient(
      49.55% 33.08% at 50% 34.91%,
      #a36ef7 0%,
      #9a69f8 24.65%,
      rgba(26, 28, 33, 0.03) 88.19%
    ),
    #232631;
  padding: 1.2rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 1px solid #925fff;
}


.building-cost-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .building-resourse-image {
    display: flex;
    background: #333644;
    justify-content: center;
    border-radius: 0.5rem;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.3rem;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .building-resourse-text-container {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
    justify-content: center;
    align-items: center;
  }
}

.building-cost-container
  .building-resourse-text-container
  .building-resourse-type {
  color: #b4b6c1;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
}

.building-cost-container
  .building-resourse-text-container
  .building-resourse-number {
  color: white;
  font-family: Poppins;
  font-weight: 700;
  font-size: 16px;
}

.building-info-container .building-level-image,
.building-info-container-locked .building-level-image {
  display: flex;
  margin: 0 auto;
  margin-top: -0.5rem;
  margin-bottom: -2rem;
}

.building-info-container .building-rate-container {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: #333644;
  height: 4rem;
  padding: 0.5rem 1rem;
  box-sizing: border-box;

  .rate-info-container {
    margin-left: 1rem;
  }
}

.building-info-container .select-farming-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  
  .number-of-workers {
    background: #333644;
    border-radius: 0.5rem;
    border: none;
    height: 3.5rem;
    width: 5rem;
    color: white;
    font-size: 16px;
    padding-left: 0.8rem;
    box-sizing: border-box;
  }
}


.building-info-container .select-farming-container > p {
  color: #b4b6c1;
  font-family: Poppins;
  font-weight: 500;
  font-size: 12px;
  margin-right: 1rem;
  margin-left: 0.8rem;
}

.rate-info-container {
  .resourse-rate-text {
    color: white;
    font-family: Poppins;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
  }
  
  .hour-rate-container {
    display: flex;
    align-items: center;
  }
  
  .hour-rate-text {
    color: #c580f3;
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    margin-left: 0.3rem;
  }
}

@media (max-width: 768px) {
  .modal-root-buildings {
    overflow: scroll;
    max-height: 100%;
  }
  /* hide scroll bar */
  .modal-root-buildings::-webkit-scrollbar {
    display: none;
  }
  .modal-root-buildings {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  /* end of hide scrollbar */

  .buildings-modal-body {
    flex-direction: column;
    background-color: #1a1c22;
  }
  .buildings-modal-body .building-info-container, .buildings-modal-body .building-info-container-wrapper  {
    width: 100%;
    margin-bottom: 1rem;
  }
}

