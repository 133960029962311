.modal-body-container.modal-body-leaderboard{
  color: white;
  padding-top: 24px;

  .leaderboard-table-container {
    padding-top: 1px;
    overflow-y: scroll;
    min-height: 20vh;
    max-height: 80vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
  
    &::-webkit-scrollbar {
      display: none;
    }

    .leaderboard-table {
      width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;

      .score-row:nth-child(1n) {
        background-color: #232631;
      }
      
      .score-row:nth-child(2n) {
        background-color: rgba(35, 37, 49, 0.6);
      }

      td:first-child {
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
      }

      td:last-child {
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
      }
  
      td {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      td:nth-child(2) {
        width: 20%;
      }

      .score-row {
        height: 5rem;
        margin-bottom: 0.2rem;
        width: 100%;
        text-align: center;
        display: table-row;
    
        .score-row-resourses {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.85rem;

            img {
              margin: 0 0.15rem;
            }
            
          }
        }

        .score-row-resourses .resource {
          min-width: 40%;
        }
        .number-of-workers {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .total-score {
          display: flex;
          align-items: center;

          span {
            padding-right: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .leaderboard-table tr {
    width: 100%;
  }
  .leaderboard-table .score-row-resourses {
    display: none;
  }
}

@media (max-width: 576px) {
  .leaderboard-table tr td:first-child {
    display: none;
  }
  .leaderboard-table tr td:nth-child(2) {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
}
@media (max-width: 440px) {
  .leaderboard-table .score-row-workers {
    display: none;
  }
}
