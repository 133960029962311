.gradient-button-green {
  background: linear-gradient(180deg, #afec4f 0%, #1c7b1c 100%);
}

.gradient-button-green:hover {
  background: linear-gradient(180deg, #cef88d 0%, #35a035 100%);
}

.gradient-button-green.disabled:hover {
  background: grey;
}

.gradient-button.disabled {
  background: grey;
  cursor: not-allowed;
}

.gradient-button {
  height: 2.5rem;
  width: 11.25rem;
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  .gradient-button-text {
    color: white;
    font-family: Poppins;
    font-weight: 800;
    font-size: 16px;
    text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.38);
  }

  &:hover {
    cursor: pointer;
  }
}

.gradient-button-pink {
  background: radial-gradient(
      116.41% 37.5% at 49.78% 0%,
      rgba(201, 174, 255, 0.91) 1.47%,
      rgba(255, 255, 255, 0) 46.52%
    ),
    linear-gradient(180deg, #8a61fa 0%, #d588f1 100%);
  background: #8A61FA;
}

.gradient-button-pink:hover {
  background: #A188E7;
}

.disabled.gradient-button-pink:hover {
  background: grey;
  cursor: not-allowed;
}
