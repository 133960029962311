.modal-body-mobile-balances {
  .balance-container {
    display: flex;
    flex-direction: row;
    background-color: #383A44;
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;
    height: 6rem;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;

    .balance-image-and-text-container {
      display: flex;
      flex-direction: row;
    }
    .balance-image-container {
      background-color: #4C4F57;
      border-radius: 0.5rem;
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }

    .balance-text-container {
      color: white;
      font-weight: bold;
      font-family: Poppins;
      font-size: 1.1rem;
      line-height: 1.5rem;
      
      p {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

      .balance-with-icon-container {
        display: flex;
        align-items: center;

        svg {
          margin-right: 0.2rem;
        }
      }
    }
    .trade-btn-container {
      border: 1px solid #825DFB;
      border-radius: 0.5rem;
      width: 5rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    
      span {
        color: white;
        font-weight: bold;
        font-family: Poppins;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  .airdrop-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #512da8;
    height: 3.5rem;
    padding: 0 1rem;
    border-radius: 4px;
    border: none;
    margin-top: 1rem;

    img {
      margin-left: -0.5rem;
      margin-right: -0.15rem;
      width: 2.5rem;
      height: 2.5rem;
    }
    span {
      color: white;
      font-size: 1.2rem;
      font-weight: bold;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .airdrop-button.disabled {
    background-color: #A9A9A9;
  }
}