.balances-sheet-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 1.2rem;
  padding: 0.625rem 1.25rem;
  opacity: 0.8;
  background: linear-gradient(227.86deg, #232631 2.47%, #444a5c 97.53%);
  border-radius: 1rem;
  justify-content: space-between;
  left: 50%;
  transform: translate(-50%, 0);

  .balance-container {
    width: 7.5rem;
    display: flex;

    img {
      margin-right: 2px;
      width: 2.5rem;
    }

    .balance-text {
      display: flex;
      align-items: center;
      color: white;
      font-family: Poppins;
      font-size: 1rem;
    }
  }

  img.meta-resource {
    height: 1.9rem;
    padding-top: 3px;
    padding-right: 0.5rem;
    opacity: 100;
  }

  .airdrop-button {
    display: flex;
    align-items: center;
    background-color: #512da8;
    height: 3rem;
    padding: 0 1rem;
    border-radius: 4px;
    border: none;
    img {
      margin-left: -0.5rem;
      margin-right: -0.15rem;
    }
    span {
      color: #fff;
      font-size: 0.9rem;
      font-weight: bold;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .airdrop-button.disabled {
    background-color: #A9A9A9;
  }
}


@media (max-width: 768px) {
  .balances-sheet-container {
    display: none;
  }
}
