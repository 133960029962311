.quests-table-container {
  overflow-y: scroll;
  min-height: 20vh;
  max-height: 50vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.quests-table-container::-webkit-scrollbar {
  display: none;
}

.quests-table {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  .quests-table-header .quests-table-header-text {
    color: #b4b6c1;
    font-family: "Roboto";
    font-weight: 400;
    font-size: 0.9rem;
  }

  thead td {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
  }

  .quest-row:hover {
    outline: 1px solid #8A61FA;
    border-radius: 1rem;
    cursor: pointer;
  }
  
  .quest-row.coming-soon {
    filter: blur(3px);
  }

  .quest-row:nth-child(1n) {
    background-color: #232631;
  }
  
  .quest-row:nth-child(2n) {
    background-color: rgba(35, 37, 49, 0.6);
  }

  .quest-row {
    width: 100%;
    display: table-row;
    height: 4rem;
  
    span {
      color: white;
      font-family: Poppins;
      font-weight: 500;
      font-size: 1rem;
    }

    td:first-child {
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
      width: 40%;
    }

    td {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    td:last-child {
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    .quest-rewards-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;

      .quest-details-reward {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 0.5rem;
    
        span {
          margin-top: 0.1rem;
          color: #FFBA01;
          font-size: 1rem;
          font-weight: 600;
          font-family: Poppins;
        }

        img {
          margin-right: 0.4rem;
          width: 2rem;
        }
      }
    }
  

    .quest-actions-container {
      /* hide mobile buttons on desktop view */  
      .quest-mobile-button {
        display: none;
      }
  
      .quest-buttons {
        flex-direction: row;
        display: flex;
        justify-content: flex-end;
        
        .gradient-button {
          width: 5rem;
        }
        
        .gradient-button:last-child {
          margin-left: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .quests-table-container {
    max-height: max-content;
    padding-bottom: 3rem;
  }
  .modal-body-container.quests-body-container {
    height: 100vh;
  }
  /* hide scroll bar */
  .modal-body-container.quests-body-container::-webkit-scrollbar {
    display: none;
  }
  .modal-body-container.quests-body-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  /* end of hide scrollbar */
  
  .modal-root-quests, .modal-root-quest-details {
    max-height: 100%;
  }
  /* view and claim buttons */
  .quest-row {    
    .quest-title-container {
      span {
        font-size: 0.9rem;
      }
    }

    td:last-child {
      width: 5%;
    }
  
    td.completed {
      width: 30% !important;
    }

    .quest-actions-container {
      .quest-buttons {
        display: none !important;
      }
    
      .quest-mobile-button {
        display: flex !important;
        justify-content: flex-end;
      }
    }
  }

  /* hide reward details data */
  .quests-table-header tr td:nth-child(2), .quest-row td:nth-child(2) {
    display: none;
  }

  .modal-container {
    max-height: none;
  }
  .modal-container.quest-details-container  {
    height: 100%;
  
    .quest-details-image-container {
      display: none;
    }
   
    .quest-details-modal-body {
      padding: 1rem;
      
      .quest-details-info-container {
        padding: 0.2rem;
        width: 100%;
      }
    }

    .modal-header-container {
      height: 3rem;
      width: 100%;
    }
  }

}

