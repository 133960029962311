/* !important needed to overwrite lib classes */
.tooltip-container {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  height: 3rem !important;
  width: 3.5rem !important;
  padding: 8px !important;
  border-radius: 6px !important;
}

.onboarding-container {
  background-color: #1a1c22;
}

#root {
  width: 80%;
  max-width: 75rem;
  padding: 0.6rem;
  margin: 0 auto;
  height: 100vh;
}

#mobile-map {
  display: none;
}


@media (max-width: 768px) {
  /* temp fix */
  #map {
    display: none;
  }

  #mobile-map {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    // should include balances-sheet
    padding-bottom: 3rem;
    background-color: #1a1c22;

    
    .building-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      background: radial-gradient( 49.55% 33.08% at 50% 34.91%, #a36ef794 0%, #9a69f861 24.65%, rgba(26, 28, 33, 0.03) 88.19% ), #232631;
      padding: 1.2rem;
      box-sizing: border-box;
      border-radius: 0.5rem;
      border: 1px solid #925fff;
      
      .building-level-text {
        position: absolute;
      }

      span {
        color: white;
        text-align: center;
        font-size: 1.2rem;
      }

      .image-container {
        display: flex;
        justify-content: center;
        
        img {
          height: 45%;
          width: 90%;
        }
      }
      .building-details-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 0.5rem;
      
        .building-text-container {
          display: flex;
          flex-direction: column;

          .building-rate-text {
            font-weight: 600;
          }
          .building-rate-type {
            color: #B4B6C1;
            font-size: 0.9rem;
          }
        }

        .resourse-image-container {
          margin-top: 0.5rem;
          margin-right: 1rem;
          background-color: #4C4F57;
          border-radius: 0.5rem;
          width: 3.5rem;
          height: 3.5rem;
          padding: 0.3rem;
        
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .building-container::before {
      content: "";
      position: absolute;
      border-radius: 8px;
      border: 1px solid transparent;
      background: radial-gradient(
            100% 100% at 49.78% 0%,
            #925fff 0%,
            #c580f3 39.75%,
            rgba(255, 255, 255, 0) 93.4%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        linear-gradient(180deg, #232631 0%, rgba(0, 0, 0, 0) 100%) border-box;
      -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }

  #root {
    width: 100% !important;
    padding: 0 !important;
  }
}

