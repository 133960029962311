.custom-switch-container {
  display: flex;
  margin: 0 auto;
  width: 50%;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  height: 2.5rem;
  flex-direction: "row";
  align-items: "center";
  justify-content: center;
  align-content: center;
  background-color: #383A44;

  .option-container.active {
    background-color: #8A61FA;
  }

  .option-container {
    display: flex;
    border-radius: 0.45rem;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 2px;

    .divider {
      width: 2px;
      height: 80%;
      background-color: #545458;
    }

    .option-text-container {
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 3rem;
      justify-content: center;
      align-items: center;

      p {
        color: white;
        font-size: 1rem;
        font-family: Poppins;
        margin: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .custom-switch-container {
    width: 100%;
  }
}
