.left-navigation-container {
  position: absolute;
  width: 85px;
  left: 38px;
  top: 40%;

  .left-navigation-avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;

    .avatar-image {
      position: absolute;
    }
    .background-container {
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      align-items: flex-end;

      svg {
        width: 32px;
        height: 32px;
        position: absolute;
        right: 0;
      }
    }
  }

  .show-all-btn {
    background: linear-gradient(227.86deg, #232631 2.47%, #444a5c 97.53%);
    border-radius: 4px;
    width: 100%;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }
  
  .show-all-btn:hover {
    cursor: pointer;
  }
  
  /* border-gradient */
  .show-all-btn::before {
    height: 35px;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 4px;
    border: 1px solid transparent;
    background: linear-gradient(132.27deg, #4d536b 2.05%, #222631 100.47%)
        border-box,
      linear-gradient(0deg, #000000, #000000) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  
  .show-all-btn-text {
    color: white;
    text-shadow: 1px 1px 0px #000000;
    font-family: Poppins;
    font-weight: 700;
    font-size: 14px;
  }
}
