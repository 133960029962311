.quest-details-container {
  max-width: 800px;
  margin-top: 10%;
  max-height: 60%;

  .quest-details-modal-body {
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;

    .quest-details-info-container {
      padding-left: 2rem;
      width: 55%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10%;


      .quest-details-name {
        color: white;
        font-size: 1.5rem;
        font-weight: 700;
        font-family: Poppins;
        margin-bottom: -5px;
      }

      .quest-details-desc {
        color: white;
        font-size: 0.9rem;
        font-family: Poppins;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }

      .quest-details-rewards-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .quest-details-reward {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.5rem;
          width: 31%;
          height: 3.125rem;
          margin-right: 2%;
          border: 1px solid #E08DEF;
          background: linear-gradient(124.61deg, rgba(130, 93, 251, 0.14) 14.75%, rgba(224, 141, 239, 0.14) 95.23%), #1C1D23;
          border-radius: 0.5rem;

          img {
            width: 2.5rem;
            margin-right: 0.5rem;
          }

          .quest-details-reward-text {
            color: #FFBA01;
            font-size: 1.25rem;
            font-weight: 600;
            font-family: Poppins;
          }
        }
      }
    }

    .quest-details-image-container {
      width: 45%;

      .quest-details-image {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .quest-details-modal-body {  
    .quest-details-rewards-container {
      .quest-details-reward {
        img {
          width: 2rem !important;
        }
      }
    }
    .quest-details-reward-text {
      font-size: 1rem !important;
    }
  }
}