.workers-container::-webkit-scrollbar {
  display: none;
}

.modal-body-container.modal-body-workers {
  padding-bottom: 0 !important;
}

.workers-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-top: -0.75%;
  overflow-y: scroll;
  height: 50vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
   
  .worker-container {
    display: flex;
    align-self: flex-start;
    flex: 1 0 24%;
    flex-direction: column;
    margin: 1%;
    border-radius: 0.5rem;
    background-color: #383A44;
    max-width: 23%;
    position: relative;

    .worker-image.busy {
      filter: grayscale(1);
      cursor: not-allowed;
    }

    .worker-image {
      width: 100%;
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }

    .checkbox.active {
      background-color: #8A61FA;
    }

    .checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      width: 1rem;
      height: 1rem;
      background-color: #4C4F57;
      border: 1px solid #727375;
      border-radius: 2px;
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 999;
    }

    .worker-info-container {
      margin: 0.75rem;
      font-family: Poppins;

      .worker-info-name {
        margin: 0;
        color: white;
        font-size: 1rem;
      }

      .worker-info-status {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          margin-left: 4px;
          color: #B4B6C1;
          font-size: 0.75rem;
        }

        .worker-status-free {
          background-color: #52AB73;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 4px;
        }

        .worker-status-busy {
          background-color: #FFBA01;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 4px;
        }
      } 
    }
  }
}

.worker-container.active {
  outline: 2px solid #8A61FA;
}

@media (max-width: 768px) {
  .modal-root-workers {
    max-height: 80% !important;
  }
  .workers-container {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
  }
  .workers-container > div {
    max-width: 47% !important;
    flex: 50% !important;
    margin: 0;
    margin-bottom: 0.5rem !important;
    
    p {
      margin: 0;
    }
    
    .worker-info-container  {
      margin: 0.5rem !important;
    }
  }
}
