* {
  box-sizing: border-box !important;
}

body {
  cursor: url('../../public/images/cursor-2.png'), default;
  overflow-x: hidden !important;
}

a {
  cursor: url('../../public/images/cursor-2.png'), default !important;
}

.center-text {
  text-align: center;
}

.hidden {
  display: none !important;
}

.display {
  display: block;
}

.hide {
  display: none;
}

.loading {
  color: #fff;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  padding: 12.5rem 0;
  
  img {
    width: 4rem;
  }
}

.non-active {
  span, p {
    color: gray;
  }
  &:hover {
    cursor: not-allowed;
  }
}

// input styles
.text-input-container input {
  border: 1.5px solid #4C4F57;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  background-color: #333644;
  border-radius: 4px;
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.modal-root-quests, .modal-root-quest-details, .modal-root-leaderboard {
  max-height: 80%;
}

@media (max-width: 768px) {
  .modal-root-quests, .modal-root-quest-details, .modal-root-leaderboard {
    max-height: 100%;
  }
  
}

.modal-header-container {
  max-height: 80%;
  max-width: 62.5rem;
  margin: 6% auto;
  margin-bottom: 0;
}

.modal-container {
  max-height: 80%;
  max-width: 62.5rem;
  margin: 6% auto;
  margin-top: 0;
  max-height: 85%;
  overflow-y: hidden;
  border-radius: 0.5rem;
  background-color: #1a1c22;
  z-index: 2;
  outline: none;

  .modal-body-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding: 2rem 1.5rem;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .modal-footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #1a1c22;
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
  }
}

@media (max-width: 768px) {
  .modal-container {
    margin-top: 0 !important;
    width: 100%;
    height: 100%;
    max-height: 100% !important;
    overflow: visible;

    .modal-body-container {
      height: 100%;
      overflow-x: hidden !important;
      padding: 1rem;
    }

    .modal-footer-container .gradient-button {
      height: 3rem !important;
      width: 80%;
    }
  }

  .modal-close-icon {
    display: none;
  }
}


