.modal-body-profile {
  width: 75%;

  span, p {
    color: white;
  }

  .input-raw-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    > div {
      min-width: 20%;
    }

    span.fab, svg {
      margin-right: 0.5rem;
    }

    .input-username-container {
      display: flex;
      align-items: center;
    }
  }

  .divider {
    margin: 1rem 0;
    height: 1px;
    background-color: #545458;
  }

  .referal-container {
    margin-bottom: 1rem;

    p {
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      filter: blur(2.5px);
      height: 3rem;
      border: 1px solid #545458;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
    }
  }
}